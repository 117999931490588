import styled from "styled-components";
import { colors } from "../../../styles/variables";
import { Device } from "../../../styles/screenSizes";
import { Alert } from "../../alerts/Alert";

export const StyledLoginForm = styled.div`
  margin: auto;
  max-width: 454px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 10px;

  @media ${Device.mobile} {
    padding: 0 20px 95px;
  }
`;

export const StyledLogo = styled.img`
  width: 160px;
  height: 30px;
  object-fit: contain;
  object-position: center;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const StyledAccountLogo = styled.img`
  margin-bottom: 70px;
`;

export const StyledForm = styled.form`
  width: 100%;
  margin-top: 32px;
`;

export const StyledLoginButton = styled.div`
  padding: 10px 0;
`;

export const StyledError = styled.span`
  font-size: 14px;
  line-height: 1.3;
  color: ${({ theme }) => theme.color.error};
  margin-top: 5px;
  display: block;
  margin-bottom: 10px;
  text-align: center;
  a {
    color: ${({ theme }) => theme.color.error};
    text-decoration: underline;
  }
`;

export const StyledResetPassword = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    font-size: 15px;
    color: ${colors.gray600};
    text-decoration: underline;
    font-weight: normal;
  }
`;

export const StyledLinkWrapper = styled.div`
  font-size: 40px;
  margin-bottom: 20px;
  > :hover {
    cursor: pointer;
  }
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: 20px;
`;
