import currency from "currency.js";
import {
  CheckoutSessionFragment,
  SubmitOrderMutation,
  LineItemFragment,
} from "v3/services/graphql/generated";

interface FormattedOrderItem {
  productId?: string;
  quantity: number;
  price: number;
  rowTotal: number;
  currency: string;
  gtin: string;
}

export interface FormattedOrder {
  // uuid
  orderId: string;
  // internal/external order id
  submissionUid: string;
  appId: string;
  total: number;
  subTotal: number;
  shipping: number;
  tax: number;
  discount: number;
  email: boolean;
  phone: boolean;
  username?: string | null;
  emailCapture?: string | null;
  mailingAddress: boolean;
  paymentMethod?: string | null;
  lineItems: FormattedOrderItem[];
}

const getValue = (number: number) =>
  currency(number, { fromCents: true }).value;

const getLineItems = (lineItems: LineItemFragment[]): FormattedOrderItem[] => {
  return lineItems.map((lineItem) => {
    const { perUnitPrice, quantity } = lineItem || {};
    const rowTotal = currency(perUnitPrice * quantity, {
      fromCents: true,
    }).value;
    const price = currency(perUnitPrice, {
      fromCents: true,
    }).value;

    return {
      productId:
        lineItem.__typename === "BundleLineItem"
          ? lineItem.bundleId
          : lineItem.itemId,
      quantity,
      price,
      rowTotal,
      gtin:
        lineItem.__typename === "BundleLineItem"
          ? lineItem.bundleId
          : lineItem.gtin,
      currency: "USD",
    };
  });
};

export const formatCheckoutSession = (
  appId: string,
  checkoutSession: CheckoutSessionFragment
): FormattedOrder => {
  const {
    contactInfo,
    id: orderId,
    payment,
    price,
    submissionUid,
    user,
  } = checkoutSession;

  const { email, phone, shippingAddress } = contactInfo || {};
  const { paymentMethod } = payment || {};

  const discount = getValue(price.discount);
  const shipping = getValue(price.shipping);
  const subTotal = getValue(price.subtotal);
  const tax = getValue(price.taxSalesTotal);
  const total = getValue(price.total);

  const lineItems = getLineItems(price.lineItems);
  const username = user?.id || null;

  return {
    appId,
    discount,
    email: !!email,
    lineItems,
    mailingAddress: !!shippingAddress,
    orderId,
    paymentMethod: paymentMethod?.toLowerCase(),
    phone: !!phone,
    shipping,
    submissionUid,
    subTotal,
    tax,
    total,
    username,
  };
};

export const formatSubmittedOrder = (
  appId: string,
  submittedOrder: NonNullable<SubmitOrderMutation["submitOrder"]>
): FormattedOrder => {
  const {
    contactInfo,
    id: orderId,
    paymentRequest,
    price,
    submissionUid,
    user,
  } = submittedOrder;

  const { email, phone, shippingAddress } = contactInfo || {};
  const { paymentMethod } = paymentRequest || {};

  const discount = getValue(price.discount);
  const shipping = getValue(price.shipping);
  const subTotal = getValue(price.subtotal);
  const tax = getValue(price.taxSalesTotal);
  const total = getValue(price.total);

  const lineItems = getLineItems(price.lineItems);
  const username = user?.id || null;

  return {
    appId,
    discount,
    email: !!email,
    // emailCapture: email,
    lineItems,
    mailingAddress: !!shippingAddress,
    orderId,
    paymentMethod: paymentMethod?.toLowerCase(),
    phone: !!phone,
    shipping,
    submissionUid,
    subTotal,
    tax,
    total,
    username,
  };
};
