export const ANALYTICS_EVENTS = {
  checkoutItemQtyChange: "checkout.itemQtyChange",
  continueToPurchaseConfirmation: "continuedToPurchaseConfirmation",
  orderPurchaseError: "orderPurchaseError",
  orderSummaryView: "orderSummaryPageView",
  couponCodeApplied: "couponCodeApplied",
  purchaseCompleted: "purchase.completed",
  purchaseEmailOptIn: "purchase.emailOptIn",
  subDetailsCancelClick: "subscriptionDetails.cancelButtonClick",
  subDetailsEditAddressClick: "subscriptionDetails.editAddressClick",
  subDetailsEditPaymentClick: "subscriptionDetails.editPaymentClick",
  subDetailsPageView: "subscriptionsDetails.PageView",
  subDetailsPauseClick: "subscriptionDetails.pauseButtonClick",
  subDetailsResumeClick: "subscriptionDetails.resumeButtonClick",
  subsListPageView: "subscriptionsList.pageView",
  userChanged: "userChanged",
  addShippingInfo: "addShippingInfo",
  orderReview: "orderReview",
};
