import TagManager from "react-gtm-module";
import {
  CheckoutSessionFragment,
  SubmitOrderMutation,
} from "v3/services/graphql/generated";
import {
  formatCheckoutSession,
  formatSubmittedOrder,
} from "v3/helpers/analytics";
import { ANALYTICS_EVENTS } from "v3/constants/analyticsEvents";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sendEvent = (event: string, eventDetails: any): void => {
  const tagManagerArgs = {
    dataLayer: {
      event,
      ...eventDetails,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const logOrderSummaryPageView = (
  cart: CheckoutSessionFragment["cart"],
  username?: string | null,
  email?: string | null
): void => {
  sendEvent(ANALYTICS_EVENTS.orderSummaryView, {
    cart,
    email: !!email,
    // emailCapture: email,
    username,
  });
};

export const logContinueToPurchaseConfirmation = (
  appId: string,
  checkoutSession: CheckoutSessionFragment
): void => {
  const formattedOrder = formatCheckoutSession(appId, checkoutSession);

  sendEvent(ANALYTICS_EVENTS.continueToPurchaseConfirmation, {
    submission: formattedOrder,
  });
};

export const logAddShippingInfo = (
  cart: CheckoutSessionFragment["cart"],
  shippingTier?: string | null,
  username?: string | null,
  email?: string | null
): void => {
  sendEvent(ANALYTICS_EVENTS.addShippingInfo, {
    cart,
    shippingTier,
    email: !!email,
    username,
  });
};

export const logOrderReview = (
  cart: CheckoutSessionFragment["cart"],
  username?: string | null,
  email?: string | null
): void => {
  sendEvent(ANALYTICS_EVENTS.orderReview, {
    cart,
    email: !!email,
    username,
  });
};

export const logPurchaseComplete = (
  cart: CheckoutSessionFragment["cart"],
  appId: string,
  apiBaseUrl: string,
  submittedOrder: NonNullable<SubmitOrderMutation["submitOrder"]>
): void => {
  const bazaarvoice = submittedOrder.bazaarvoice || {};
  const formattedOrder = formatSubmittedOrder(appId, submittedOrder);

  sendEvent(ANALYTICS_EVENTS.purchaseCompleted, {
    ...(!!Object.keys(bazaarvoice).length && { bazaarvoice }),
    ...formattedOrder,
    cart,
    apiBaseUrl,
  });
};

export const logOrderPurchaseError = (
  error: Error,
  email?: string | null,
  username?: string
): void => {
  sendEvent(ANALYTICS_EVENTS.orderPurchaseError, {
    ...error,
    email: !!email,
    username,
  });
};

export const logCouponCode = (
  couponCode: string,
  valid: boolean,
  user: CheckoutSessionFragment["user"] | null
): void => {
  const { email, id } = user || {};
  sendEvent(ANALYTICS_EVENTS.couponCodeApplied, {
    couponCode: {
      couponCode,
      valid,
    },
    email: !!email,
    username: id,
  });
};

export const logUserChange = (username: string | null): void => {
  sendEvent(ANALYTICS_EVENTS.userChanged, { username });
};

export const logSubscriptionEvent = (
  event: string,
  username: string,
  subscriptionId?: string
): void => {
  sendEvent(event, {
    username,
    ...(subscriptionId && { subscriptionId }),
  });
};

export const logPurchaseEmailOptIn = (email: string): void => {
  sendEvent(ANALYTICS_EVENTS.purchaseEmailOptIn, { email });
};
