import styled from "styled-components";
import { Heading } from "@pepdirect/v3/ui/Heading";

export const StyledForm = styled.div`
  padding-top: 60px;
`;

export const StyledSignup = styled.div`
  width: 100%;
`;

export const StyledHeading = styled(Heading)`
  margin: 70px 0 20px;
`;
