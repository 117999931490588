import React, { FC, ReactNode, useContext, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { sanitizeUrl } from "@braintree/sanitize-url";
import { ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";
import { TenantContext } from "v3/context/tenant";
import { LocalizationContext } from "v3/context/localization";
import { parseTemplateAsString } from "@pepdirect/v3/helpers/localization";
import {
  LoginForm as PepLoginForm,
  LoginFormValues,
} from "@pepdirect/v3/ui/LoginForm";
import { AlertTypes } from "@pepdirect/v3/ui/Alert";
import { Button } from "@pepdirect/v3/ui/Button";
import {
  BrandConfigCustomizationsImage,
  useLoginUserMutation,
} from "v3/services/graphql/generated";
import { logUserChange } from "v3/services/analytics";
import { getLinkFromTenant } from "v3/helpers/tenant";
import { getRedirectUrl } from "v3/helpers/routing";
import { parseLoginGraphQLErrors } from "@pepdirect/v3/helpers/errors";
import { PageRoutes } from "v3/constants/pageRoutes";
import { StyledForm, StyledHeading, StyledSignup } from "./index.styled";
import AccountVerification from "v3/components/AccountVerification";

export enum LoginQueryParams {
  expired = "expired",
  invalid = "invalid",
  verified = "verified",
}

export const LoginForm: FC = () => {
  const { tenant } = useContext(TenantContext);
  const { localization } = useContext(LocalizationContext);
  const {
    headingText = "Login to ${tenantName}",
    headingDefaultText = "your account",
    signUpHeadingText = "New to ${tenantNameText}?",
    signUpDefaultHeadingText = "New here?",
    signUpButtonText = "Create an account",
  } = localization?.forms?.signIn || {};
  const {
    invalidLinkText = "Your verification link is invalid, please log in again to verify your account.",
    tokenExpiredText = "Your verification link has expired, please log in again to verify your account.",
    verifySuccessText = "Your account has been successfully verified. You can now log in.",
  } = localization?.portal?.accountVerification || {};

  const [isLoading, setIsLoading] = useState(false);
  const [emailPassword, setEmailPassword] = useState<{
    email: string;
    password: string;
  }>({ email: "", password: "" });
  const [showVerificationPage, setShowVerificationPage] = useState(false);
  const [mappedErrorMessage, setMappedErrorMessage] = useState<
    string | ReactNode
  >("");
  const [loginMutation] = useLoginUserMutation();
  const router = useRouter();

  const canSignUp = tenant?.capabilities.signUp.enabled;
  const { successRedirectTo, successRedirectSite } = router.query;

  const privacyLink = getLinkFromTenant(tenant, "privacy");

  const accountIconUrl = tenant?.brandConfig?.customizations?.images?.find(
    (img: BrandConfigCustomizationsImage) => img.name === "accountIconUrl"
  );

  const signinPageTitle =
    tenant?.brandConfig?.customizations?.account?.signinPageTitle ??
    parseTemplateAsString(headingText, { tenantName: headingDefaultText });

  const alertDetails = useMemo<
    { text: string; alertType: AlertTypes } | undefined
  >(() => {
    if (router.query[LoginQueryParams.verified] === "false") {
      return {
        text:
          router.query[LoginQueryParams.expired] === "true"
            ? tokenExpiredText
            : invalidLinkText,
        alertType: "warning",
      };
    }
    if (router.query[LoginQueryParams.verified] === "true") {
      return {
        text: verifySuccessText,
        alertType: "success",
      };
    }
    return;
  }, [invalidLinkText, router, tokenExpiredText, verifySuccessText]);

  const onSubmit = async ({ email, password }: LoginFormValues) => {
    setEmailPassword({ email, password });
    setIsLoading(true);
    try {
      const { data } = await loginMutation({
        variables: { email, password },
      });
      logUserChange(data?.loginUser?.id);
      const redirectUrl = getRedirectUrl(
        successRedirectTo && sanitizeUrl(successRedirectTo as string),
        successRedirectSite && sanitizeUrl(successRedirectSite as string),
        tenant?.fallbackUrl
      );
      router.push(redirectUrl || "/dashboard/account");
    } catch (e) {
      setIsLoading(false);

      if (e instanceof ApolloError && e.graphQLErrors.length) {
        const { errorMsg, passwordResetRequired, emailVerificationRequired } =
          parseLoginGraphQLErrors(
            e.graphQLErrors as GraphQLError[],
            localization?.forms?.signIn
          );

        if (passwordResetRequired) {
          router.push(PageRoutes.updatePassword);
        } else if (emailVerificationRequired) {
          setShowVerificationPage(true);
        } else {
          setMappedErrorMessage(errorMsg);
        }
      }
    }
  };

  const goToForgotPassword = () => {
    router.push({
      pathname: "/forgot-password",
      query: {
        ...(successRedirectTo && { successRedirectTo }),
        ...(successRedirectSite && { successRedirectSite }),
      },
    });
  };

  const goToCreateAccount = () => {
    const { successRedirectTo, successRedirectSite } = router.query;
    router.push({
      pathname: PageRoutes.signUp,
      query: {
        ...(successRedirectTo && { successRedirectTo }),
        ...(successRedirectSite && { successRedirectSite }),
      },
    });
  };

  return (
    <>
      {showVerificationPage ? (
        <AccountVerification {...emailPassword} />
      ) : (
        <StyledForm>
          <PepLoginForm
            accountLogo={accountIconUrl}
            alertDetails={alertDetails}
            altsLocalization={localization?.portal?.alts}
            error={mappedErrorMessage}
            goToForgotPassword={goToForgotPassword}
            isLoading={isLoading}
            customText={tenant?.brandConfig.customTexts || []}
            onSubmit={onSubmit}
            privacyUrl={privacyLink?.url}
            signInLocalization={localization?.forms?.signIn}
            signinPageTitle={signinPageTitle}
            validationLocalization={localization?.forms?.validation}
          >
            {canSignUp && (
              <StyledSignup>
                <StyledHeading level="h3" size="m">
                  {tenant?.brandConfig.title
                    ? parseTemplateAsString(signUpHeadingText, {
                        tenantNameText: tenant?.brandConfig.title,
                      })
                    : signUpDefaultHeadingText}
                </StyledHeading>
                <Button
                  type="tertiary"
                  fullWidth
                  onClick={goToCreateAccount}
                  disabled={isLoading}
                >
                  {signUpButtonText}
                </Button>
              </StyledSignup>
            )}
          </PepLoginForm>
        </StyledForm>
      )}
    </>
  );
};
