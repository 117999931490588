import styled from "styled-components";
import { colors } from "../../styles/variables";

interface StyledCheckboxProps {
  checked: boolean;
  disabled: boolean;
  round?: boolean;
  hasError?: boolean;
}

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const StyledHiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
`;

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${({ checked, disabled, theme }) =>
    checked && disabled
      ? theme.color.disabled
      : checked
      ? theme.color.primary
      : "transparent"};
  border-radius: ${({ round }) => (round ? "50%" : "4px")};
  transition: all 150ms;
  border: 1px solid
    ${({ disabled, theme, hasError }) => {
      if (disabled) return theme.color.disabled;
      if (hasError) return colors.red;
      return theme.color.primary;
    }};

  :hover {
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  }

  ${StyledHiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px pink;
  }
  ${StyledIcon} {
    visibility: ${({ checked }) => (checked ? "visible" : "hidden")};
  }
`;

export const StyledCheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
