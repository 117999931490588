import styled from "styled-components";
import { colors } from "../../../styles/variables";
import { HexColor } from "../../../types";

export const StyledCheckbox = styled.div`
  margin: 10px 0 30px 0;
  width: 100%;
`;

export const StyledLabel = styled.label<{
  textColor?: HexColor;
  fontSize?: string;
}>`
  display: flex;
  width: 100%;
  min-height: 20px;
  font-size: ${({ fontSize }) => fontSize || "12px"};
  line-height: 20px;
  color: ${({ textColor }) => textColor || colors.gray900};

  div:first-child {
    margin-right: 5px;
  }
`;
