import styled from "styled-components";
import { colors } from "../../styles/variables";

export const INPUT_HEIGHT = "40px";
export const INPUT_BORDER_RADIUS = "3px";

export const StyledField = styled.div`
  /* needed for things absolutely positioned within it */
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 25px;
`;

export const StyledInputContainer = styled.div`
  position: absolute;
  width: 100%;
`;

export const StyledLabel = styled.label`
  font-size: 13px;
  /* this is meant to target the label text, wrap the text in a div so this can target it */
  & > :first-child {
    margin-bottom: 4px;
  }
`;

export const StyledInlineInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledInlineInputText = styled.div`
  font-size: 16px;
  margin-right: 10px;
`;

export const StyledInputWrapper = styled.div`
  width: 100%;
`;

export const StyledInput = styled.input<{
  valid?: boolean;
  invalid?: boolean;
  backgroundColor?: string;
}>`
  width: 100%;
  height: ${INPUT_HEIGHT};
  padding: 0 16px;
  font: inherit;
  font-size: 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: ${INPUT_BORDER_RADIUS};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "transparent"};

  border-color: ${({ valid, invalid, theme }) =>
    (valid && colors.green) ||
    (invalid && theme.color.error) ||
    colors.gray300};

  &:focus {
    border-color: ${({ valid, invalid, theme }) =>
      !valid && !invalid && theme.color.primary};
  }
`;

export const StyledTextArea = styled.textarea<{
  valid?: boolean;
  invalid?: boolean;
  resize?: "horizontal" | "vertical";
}>`
  width: 100%;
  height: 100px;
  padding: 8px 16px;
  font: inherit;
  font-size: 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: ${INPUT_BORDER_RADIUS};

  border-color: ${({ valid, invalid, theme }) =>
    (valid && colors.green) ||
    (invalid && theme.color.error) ||
    colors.gray300};

  &:focus {
    border-color: ${({ valid, invalid, theme }) =>
      !valid && !invalid && theme.color.primary};
  }
  resize: ${({ resize }) => resize || "none"};
  /* https://stackoverflow.com/a/7144960 */
  vertical-align: top;
`;

export const StyledError = styled.span`
  position: absolute;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  color: ${({ theme }) => theme.color.error};
  margin-top: 5px;
  display: block;
`;

// needs a height due to the iframe
export const StyledFieldForBraintree = styled(StyledField)`
  height: 60px;
`;

// almost the same as StyledInput but must be a div for the iframe
export const StyledInputForBraintree = styled.div<{
  valid?: boolean;
  invalid?: boolean;
}>`
  width: 100%;
  height: ${INPUT_HEIGHT};
  padding: 0 16px;
  background-color: ${colors.white};
  font: inherit;
  font-size: 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: ${INPUT_BORDER_RADIUS};

  border-color: ${({ valid, invalid, theme }) =>
    (valid && colors.green) ||
    (invalid && theme.color.error) ||
    colors.gray300};

  &:focus {
    border-color: ${({ valid, invalid, theme }) =>
      !valid && !invalid && theme.color.primary};
  }
`;

// removes fieldset default styling
export const StyledFieldSet = styled.fieldset`
  border: none;
  margin-inline-end: 0;
  margin-inline-start: 0;
  margin: 0;
  min-inline-size: unset;
  padding-block-end: 0;
  padding-block-start: 0;
  padding-inline-end: 0;
  padding-inline-start: 0;
  padding: 0;
`;
