import {
  BrandConfigCustomizationsLink,
  TenantQuery,
} from "v3/services/graphql/generated";

export const getLinkFromTenant = (
  tenant: TenantQuery["tenant"],
  location: string
): BrandConfigCustomizationsLink | undefined => {
  if (tenant?.brandConfig.customizations.links) {
    return tenant?.brandConfig.customizations.links.find(
      (link: BrandConfigCustomizationsLink) => link.location === location
    );
  }
};
