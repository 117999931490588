import React, { FC } from "react";
import { Field } from "react-final-form";
import { Checkbox } from "../../../components/Checkbox";
import { StyledCheckbox, StyledLabel } from "./index.styled";
import { HexColor } from "../../../types";

interface FieldCheckboxProps {
  color?: HexColor;
  fontSize?: string;
  label: string | JSX.Element;
  name: string;
}

export const FieldCheckbox: FC<FieldCheckboxProps> = ({
  color,
  fontSize,
  label,
  name,
}) => (
  <Field
    type="checkbox"
    name={name}
    render={({ input: { onChange, checked } }) => (
      <StyledCheckbox>
        <StyledLabel textColor={color} fontSize={fontSize}>
          <Checkbox checked={checked} onChange={onChange} />
          {label}
        </StyledLabel>
      </StyledCheckbox>
    )}
  />
);
